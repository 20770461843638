<template>
  <div>
    <div v-if="!isLoading" class="box box-default">
      <div class="box-header">
        <div class="flex flex-col-sm gap-8 align-items-center justify-between">
          <h3 class="box-title">AltCoin XChange Offline Pool</h3>
          <div class="flex flex-col-sm gap-8 align-items-center">
            <span class="text-bold text-danger">{{ searching }}</span>
            <v-select v-model="altCoinID" :options="secureCloudCoins" :reduce="coin => coin.ID" label="Name"
              placeholder="Select SecureCloud" style="min-width: 200px;"></v-select>
            <button :disabled="altCoinID === null" class="btn btn-primary btn-sm btn-flat" @click="execute()">
              Execute Offline Pool Refill
            </button>
            <input ref="searchInput" v-model="keyword" aria-label="search" class="form-control" placeholder="Search..."
              type="text" @keyup="search($event)" />
          </div>
        </div>
      </div>
      <div class="box-body no-padding">
        <va-tabs @selectedTab="selectedTab">
          <div id="overview" slot="content" :data-title="'Overview'" class="active">
            <div class="table-responsive">
              <table class="table table-condensed">
                <thead>
                  <tr>
                    <th>Coin Name</th>
                    <th>Long Name</th>
                    <th>Short Name</th>
                    <th>Total Free</th>
                    <th>Total In Use</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="overview.length > 0">
                    <tr v-for="(record, index) in overview" :key="index" class="pointer"
                      :class="[record.TotalFree > 20 ? 'bg-success' : 'bg-danger']" @click="selectAndSearch(record)">
                      <td>{{ record.Name }}</td>
                      <td class="no-wrap">{{ record.LongName }}</td>
                      <td>{{ record.ShortName }}</td>
                      <td class="no-wrap">{{ record.TotalFree }}</td>
                      <td class="no-wrap pr-16">{{ record.TotalInUse }}</td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="10">No record found!</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
          <div id="detail" slot="content" :data-title="'Detail'">
            <div v-if="meta.total" class="footer">
              <pagination :meta="meta" @pageChange="updatePage"></pagination>
            </div>
            <div class="table-responsive">
              <table class="table table-striped table-condensed">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Address</th>
                    <th class="pointer" @click="sort('Amount')">
                      Amount
                      <span v-if="sortKey === 'Amount'" class="ml-4">
                        <i class="fa fa-sort"></i>
                      </span>
                    </th>
                    <th class="pointer" @click="sort('IsInUse')">
                      IsInUse
                      <span v-if="sortKey === 'IsInUse'" class="ml-4">
                        <i class="fa fa-sort"></i>
                      </span>
                    </th>
                    <th>IsPoolMaster</th>
                    <th>Coin Name</th>
                    <th>Long Name</th>
                    <th>Short Name</th>
                    <th>Created On</th>
                    <th>Updated On</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="records.length > 0">
                    <tr v-for="(record, index) in records" :key="index">
                      <td>{{ record.ID }}</td>
                      <td class="no-wrap">
                        <button class="btn btn-primary btn-flat btn-sm mr-8" @click="balanceCheck(record)">Balance
                        </button>
                        <span class="pointer"
                          @click="launchUpdateAltCoinOfflinePoolModal(record, 'Address', 'Address', 'text')">
                          {{ record.Address }}
                        </span>
                        <a class="ml-8" :href="generateAddress(record)" target="_blank">
                          <i class="fa fa-globe"></i>
                        </a>
                      </td>
                      <td class="no-wrap">
                        {{ toFixed(record.Amount) }}
                        <span class="ml-8">
                          <i class="fa fa-copy pointer"
                            @click="toConverter(toFixed(record.Amount), record.AltCoinID)"></i>
                        </span>
                      </td>
                      <td>
                        <span class="pointer" @click="toggleAltCoinOfflinePoolBoolFields(record, 'IsInUse')"
                          v-html="formatBoolean(record.IsInUse)"></span>
                      </td>
                      <td>
                        <span class="pointer" @click="toggleAltCoinOfflinePoolBoolFields(record, 'IsPoolMaster')"
                          v-html="formatBoolean(record.IsPoolMaster)"></span>
                      </td>
                      <td>{{ record.Name }}</td>
                      <td class="no-wrap">{{ record.LongName }}</td>
                      <td>{{ record.ShortName }}</td>
                      <td class="no-wrap">{{ formatDate(record.CreatedOn) }}</td>
                      <td class="no-wrap pr-16">{{ formatDate(record.UpdatedOn) }}</td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="10">No record found!</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div v-if="meta.total" class="footer">
              <pagination :meta="meta" @pageChange="updatePage"></pagination>
            </div>
          </div>
        </va-tabs>
      </div>
    </div>
    <!-- Update Modal -->
    <update-alt-coin-offline-pool-modal></update-alt-coin-offline-pool-modal>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { mapGetters } from "vuex";
import { START_LOADING, STOP_LOADING, SAVE_CONVERTER } from "@/store/keys";
import { formatDate, toFixed, logout, saveNotification } from "@/lib/utils";
import VaTabs from "../components/VATabs";
import UpdateAltCoinOfflinePoolModal from "../components/modals/UpdateAltCoinOfflinePoolModal";

export default {
  name: "AltCoinXChangeOfflinePool",
  components: {
    UpdateAltCoinOfflinePoolModal,
    VaTabs,
    Pagination,
  },
  data() {
    return {
      overview: [],
      records: [],
      meta: {
        total: 0,
        page: 1,
        offset: 0,
        limit: Number.parseInt(process.env.VUE_APP_LIMIT, 10),
      },
      keyword: "",
      searching: "",
      altCoinID: null,
      tab: "overview",
      sortKey: "IsInUse",
      order: "desc",
    };
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "jwtToken",
      "currentUser",
      "altCoins",
      "converter",
    ]),
    secureCloudCoins() {
      return this.altCoins.filter(coin => coin.SecureCloud === 1 && coin.isOnline);
    },
  },
  methods: {
    generateAddress(record) {
      let url = record.BlockExplorer;
      switch (record.LongName) {
        case "MOON":
          url += "q=" + record.Address;
          break;
        case "BTC":
          url += record.Address;
          break;
        case "LTC":
          url += record.Address;
          break;
      }
      return url;
    },
    toConverter(amount, altCoinID) {
      const form = { ...this.converter };
      form.source = amount;
      form.sourceAltCoinId = altCoinID;
      this.$store.commit(SAVE_CONVERTER, form);
      this.copyToClipboard(amount);
    },
    copyToClipboard(val) {
      const self = this;
      navigator.clipboard.writeText(val).then(function () {
        self.$toast.fire("", "Copied to clipboard: " + val, "success");
        saveNotification("Copied to clipboard: " + val);
      }, function (err) {
        console.error("Async: Could not copy text: ", err);
      });
    },
    showLoading() {
      this.$swal.fire({
        title: "",
        text: "Please wait...",
        showConfirmButton: false,
        backdrop: true,
      });
    },
    stopLoading() {
      this.$swal.close();
    },
    updatePage(page) {
      this.getPaginatedRecords(page);
    },
    selectedTab(id) {
      this.tab = id;
    },
    search(e) {
      if (e.key !== "/" && e.key !== "Control") {
        this.searching = "Searching...";
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          if (this.tab === "overview") {
            this.getOverview(false);
          } else {
            this.getPaginatedRecords(1, false);
          }
        }, 500);
      }
    },
    selectAndSearch(record) {
      this.altCoinID = record.AltCoinID;
      $("a[href=\"#detail\"]").click();
      this.tab = "detail";
      this.keyword = record.LongName;
      this.getPaginatedRecords();
    },
    getOverview(isLoading) {
      if (isLoading) {
        this.$store.commit(START_LOADING);
      }
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=ListMisc&type=xchange_offline_pool_overview`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          keyword: this.keyword ? this.keyword.trim() : "",
        },
      }).then((response) => {
        this.$store.commit(STOP_LOADING);
        this.searching = "";
        if (response.status === 200) {
          this.overview = response.data.data;
        }
      }).catch(error => {
        this.$store.commit(STOP_LOADING);
        this.searching = "";
        this.$toast.fire("", error.data.message, "error");
        saveNotification(error.data.message);
      });
    },
    sort(key) {
      if (key !== this.sortKey) {
        this.order = "desc";
      } else {
        this.order = this.order === "asc" ? "desc" : "asc";
      }
      this.sortKey = key;
      this.getPaginatedRecords(1, false);
    },
    getPaginatedRecords(page = 1, isLoading) {
      if (isLoading) {
        this.$store.commit(START_LOADING);
      }
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=ListMisc&type=xchange_offline_pool`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          page,
          keyword: this.keyword ? this.keyword.trim() : "",
          limit: Number.parseInt(this.meta.limit),
          order: this.sortKey,
          orderBy: this.order,
        },
      }).then((response) => {
        this.$store.commit(STOP_LOADING);
        this.searching = "";
        if (response.status === 200) {
          this.records = response.data.data.records;
          this.meta = response.data.data.meta;
          if (this.keyword) {
            this.$nextTick(() => this.$refs.searchInput.focus());
          }
          if (this.$route.query.coin) {
            const coin = this.$route.query.coin;
            this.altCoinID = this.$route.query.id;
            this.$router.replace({
              ...this.$router.currentRoute,
              query: {},
            });
            $("a[href=\"#detail\"]").click();
            this.tab = "detail";
            this.keyword = coin;
            this.getPaginatedRecords();
          }
        }
      }).catch(error => {
        this.$store.commit(STOP_LOADING);
        this.searching = "";
        this.$toast.fire("", error.data.message, "error");
        saveNotification(error.data.message);
      });
    },
    balanceCheck(record) {
      this.showLoading();
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      this.$http.get(`${apiBaseUrl}/GetInfo`, {
        params: {
          AltCoinID: record.AltCoinID,
          Name: "B",
          Secure: "AA",
          OfflinePoolAddress: record.Address,
        },
      }).then(response => {
        this.stopLoading();
        if (response.data.status_code === 200) {
          if (response.data.result.toLowerCase() === "success") {
            this.$toast.fire("", response.data.message, "success");
            this.getPaginatedRecords(this.meta.page, false);
          }
        } else if (response.data.status_code === 400) {
          if (response.data.message.toLowerCase() === "no permission") {
            logout();
          }
        }
      }).catch(error => {
        this.stopLoading();
        this.$toast.fire("", error.data.message, "error");
      });
    },
    formatDate(val) {
      return formatDate(val);
    },
    formatBoolean(val) {
      const label = val ? "Yes" : "No";
      const lblClass = val ? "label-success" : "label-danger";
      return `<label class="label ${lblClass}">${label}</label>`;
    },
    toFixed(val) {
      return toFixed(val);
    },
    execute() {
      this.showLoading();
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      this.$http.get(`${apiBaseUrl}/GetInfo`, {
        params: {
          AltCoinID: this.altCoinID,
          Name: "B",
          Secure: "AA",
          OFFLINEPOOLFILL: "",
        },
      }).then((response) => {
        this.stopLoading();
        this.searching = "";
        if (response.data.status_code === 200) {
          this.$toast.fire("", response.data.message, "success");
          saveNotification(response.data.message);
          this.getPaginatedRecords(1, false);
        } else if (response.data.status_code === 400) {
          if (response.data.message.toLowerCase() === "no permission") {
            logout();
          } else {
            this.$toast.fire("", response.data.message, "error");
            saveNotification(response.data.message);
          }
        }
      }).catch(error => {
        this.stopLoading();
        this.searching = "";
        this.$toast.fire("", error.data.message, "error");
        saveNotification(error.data.message);
      });
    },
    handleSlashKey(e) {
      if (e.key === "/") {
        e.preventDefault();
        this.$refs.searchInput.focus();
      }
    },
    getOptions(key) {
      let options = [];
      switch (key) {
        case "IsInUse":
        case "IsPoolMaster":
          options = [
            {
              id: 0,
              label: "No",
            },
            {
              id: 1,
              label: "Yes",
            },
          ];
          break;
      }
      return options;
    },
    toggleAltCoinOfflinePoolBoolFields(record, key) {
      const payload = {
        MerchantID: this.currentUser.ID,
        APIKey: this.currentUser.APIKey,
        ID: record.ID,
        Key: key,
        Value: Number(record[key]) === 1 ? 0 : 1,
        output: "json",
      };
      const url = process.env.VUE_APP_API_URL;
      this.$http.post(`${url}/v2REAPI?Call=AltCoinOfflinePoolUpdate`, this.qs.stringify(payload), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      }).then(response => {
        if (response.data && response.data.status_code === 400) {
          if (response.data.message.toLowerCase() === "no permission") {
            logout();
          }
        } else {
          this.$toast.fire("", response.data.message, "success");
          saveNotification(response.data.message);
          this.getPaginatedRecords(this.meta.page, false);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    launchUpdateAltCoinOfflinePoolModal(record, key, label, type) {
      const options = this.getOptions(key);
      this.$bus.$emit("update-altcoin-offline-pool-modal", {
        title: "Update " + label,
        valueLabel: label,
        record: {
          ID: record.ID,
          Key: key,
          Value: record[key],
        },
        type,
        options,
        confirm: () => {
          this.getPaginatedRecords(this.meta.page, false);
        },
      });
    },
  },
  mounted() {
    this.getOverview(true);
    this.getPaginatedRecords(1, true);
    window.addEventListener("keydown", this.handleSlashKey);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleSlashKey);
    this.$store.commit(STOP_LOADING);
  },
};
</script>

<style scoped></style>
